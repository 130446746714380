import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


gsap.registerPlugin(ScrollTrigger);

const ClientReviews = () => {
  const containerRef = useRef(null);
  const containerRefAni = useRef(null);
  const h1Ref = useRef(null);
  const navigate = useNavigate();
  
  const handleNavigate = () => navigate("/projects");


  const reviews = [
    {
      subheading: "Harish Sharma",
      review:
        "'We had the pleasure of collaborating with Nexuz for the branding of our latest product line, Optifinish, and the experience exceeded all expectations. Nexuz demonstrated a profound understanding of our objectives.'",
    },
    {
      subheading: "Varalika Singh",
      review: "Nexuzforge transformed my company's, Architsu, journey by designing my logo and creating an animation for it! Seeing great results!",
    },
    {
      subheading: "Yuki Nakamura",
      review: "Working with Nexuzforge was an absolute delight. Their attention to detail and ability to capture the essence of our vision was truly remarkable. Highly recommend them for anyone seeking quality and creativity."
    },
    {
      subheading: "Emily Turner",
      review: "The team at Nexuzforge was incredibly professional and innovative. They brought fresh ideas to the table and executed them flawlessly. I couldn’t be happier with the results!"
    },
    {
      subheading: "James Whitaker",
      review: "Nexuzforge exceeded my expectations with their dedication and expertise. Their ability to combine functionality with aesthetics made a significant impact on my business."
    }    
  ];

  // Animate each <h2> to slide in from the right, then slide out to the left

  useLayoutEffect(() => {
    if (!containerRefAni.current || !containerRef.current || !h1Ref.current) return;
    gsap.fromTo(
      h1Ref.current,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 60%',
          end: 'top 30%',
          scrub: true,
        },
      }
    );

  const h2Elements = containerRefAni.current.querySelectorAll('h2');

  // Animate each <h2> element
  h2Elements.forEach((h2) => {
    gsap.fromTo(
      h2,
      { x: 200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: h2,
          start: 'top 80%',
          end: 'top 0%',
          scrub: true,
        },
      }
    );

    gsap.to(h2, {
      x: -300,
      opacity: 0,
      scrollTrigger: {
        trigger: h2,
        start: 'bottom 0%',
        end: 'top 0%',
        scrub: true,
      },
    });
  });

  // Pinning the container and animating background
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: containerRef.current,
      start: 'top top',
      end: '+=1200',
      scrub: true,
    },
  });

  tl.fromTo(
    containerRef.current,
    { backgroundPosition: '50% 50%' },
    {
      backgroundPosition: '100% 0%',
      ease: 'none',
    }
  );

  // Cleanup
  return () => {
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  };
}, []);


  return (
    <div
      id="client-reviews"
      className="w-screen h-auto md:rounded-t-3xl border-y-[2px] border-t-pink-400 border-b-pink-400 mb-0 relative overflow-x-hidden"
    >
      <div
        ref={containerRef}
        className="flex flex-col justify-center pt-4 min-h-screen"
        style={{
          background: 'url(/images/clientbg7.jpeg)',
          backgroundSize: '10%',
          backgroundPosition: '50% 50%',
          transition: 'background-image 0.8s ease-in-out',
        }}
      >
        <h1 ref={h1Ref} className="text-white md:text-9xl text-7xl text-center font-thin my-3 relative z-50">Reviews.</h1>
        <div className="h-auto pt-4 md:pt-16">
          <div className="text-center text-white" ref={containerRefAni}>
            {reviews.map((review, idx) => (
              <div key={idx} className="md:pb-10 pb-3 px-4">
                <h2 className="md:text-7xl text-3xl font-base">{review.subheading}</h2>
                <h2 className="md:text-3xl text-lg font-thin mt-4">{review.review}</h2>
              </div>
            ))}
          </div>
        </div>
        <div className="block mx-auto mt-auto mb-10">
            <button
              onClick={handleNavigate}
              className="text-white py-5 px-7 rounded-full flex justify-center items-center mt-4 hover:border-black border-white border-2 hover:bg-white group transition-colors duration-300"
            >
              <div className="font-medium text-3xl group-hover:text-black transition-colors duration-300">
                Start a Project
              </div>
              <div className="w-[30px] h-[30px] bg-white rounded-full ml-6 group-hover:bg-black transition-colors duration-300"></div>
            </button>
          </div>
      </div>

    </div>
  );
};

export default ClientReviews;
