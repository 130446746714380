import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const BoxDisplay = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Check if the mountRef is available
    if (!mountRef.current) return;

    // Create scene, camera, and renderer
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    const camera = new THREE.PerspectiveCamera(50, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
    camera.position.z = 5;

    // Box geometry and materials
    const geometry = new THREE.BoxGeometry(3, 2, 2);
    const textureLoader = new THREE.TextureLoader();
    const materials = [
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optiright.jpeg') }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optileft.jpeg') }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optitop.jpeg') }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optitop.jpeg') }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optifront.jpeg') }),
      new THREE.MeshStandardMaterial({ map: textureLoader.load('/images/optifront.jpeg') }),
    ];

    const box = new THREE.Mesh(geometry, materials);
    scene.add(box);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    // Animation loop
    const animate = () => {
      if (!mountRef.current) return; // Safeguard for unmounted component
      box.rotation.y += 0.01;
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };
    animate();

    // Cleanup function
    return () => {
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement); // Safely remove DOM element
      }
      renderer.dispose();
    };
  }, []);

  return (
    <div ref={mountRef} style={{ width: '100%', height: '400px' }} />
  );
};

export default BoxDisplay;
