import React, {useRef, useEffect} from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectBG from './ProjectBG';
import Footer from './Footer';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const TeamSection = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: -window.innerWidth/5, y: window.innerHeight/3. });
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const handleScroll = () => {
    setScrollOffset(window.scrollY || 0);
  };
  const handleMouseLeave = () => {
    setCursorPosition({ x: -window.innerWidth/5, y: 0 });
  };
  

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setCursorPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const handleHover = () => setIsHovered(true);
  const handleHoverLeave = () => setIsHovered(false);
  const handleNavigate = () => navigate("/projects");


  const contentArray = [
    {
      firstName: 'Kushagra',
      lastName: 'Verma',
      email: 'kush@nexuzforge.com',
      img: '/images/kush2.png',
      role: 'Product Development \n Programming.'
    },
    {
      firstName: 'Utkarsh',
      lastName: 'Sharma',
      email: 'utkarsh@nexuzforge.com',
      img: '/images/utkarsh2.png',
      role: 'Business Development \n UI/UX.'
    },
    {
      firstName: 'Raghav',
      lastName: 'Maheshwari',
      email: 'raghav@nexuzforge.com',
      img: '/images/raghav2.jpeg',
      role: 'Marketing \n Graphics.'
    },
    {
      firstName: 'Parv',
      lastName: 'Chugh',
      email: 'parv@nexuzforge.com',
      img: '/images/parv3.png',
      role: 'Creative Design \n Animations.'
    },
  ];

  const [currentState, setCurrentState] = useState(0);

  const handleNext = () => {
    setCurrentState((prevState) => (prevState + 1) % contentArray.length);
  };
  const h1Ref = useRef(null);
  // Animations

  // Animations
  useEffect(() => {
    if (!h1Ref.current) return;
  
    gsap.fromTo(
      h1Ref.current,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: h1Ref.current,
          start: 'top 70%',
          end: 'top 30%',
          scrub: true,
        },
      }
    );
  
    // Cleanup function to remove ScrollTriggers
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  


  return (
    <div id="team" className="pt-5"
    >
      <ProjectBG />
      {/* Title with animation */}
      <section
        className="relative z-10 flex flex-col md:flex-row justify-between items-center h-[80vh] md:h-screen px-8 bg-black overflow-hidden"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onScroll={handleScroll}
        // Reset cursor position on mouse leave

      >
        {/* Background GIF */}
        <div
          className="absolute inset-0 -z-20 h-full w-full transition-opacity duration-1000"
          style={{
            backgroundImage: `url('/images/readyprojectbg.gif')`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat-x",
            backgroundPosition: "center",
            opacity: isHovered ? 0.8 : 0.3, // Dynamically set the opacity with smooth transitions
          }}
        ></div>

        {/* Dynamic Circular Reveal */}
        {!isHovered && (
          <div
            className="absolute inset-0 pointer-events-none"
            style={{
              background: `radial-gradient(circle 450px at ${
                cursorPosition.x
              }px ${cursorPosition.y + scrollOffset}px, 
              rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%)`,
              mixBlendMode: "normal",
              zIndex: -10,
            }}
          ></div>
        )}

        {/* Left Side Content */}
        <div
          className={`flex flex-col md:w-1/2 ${isHovered ? "bg-black/80" : "bg-black/30"
            } md:p-8 pb-16 rounded-3xl md:items-start items-center my-auto`}
        >
          <h1 className="text-5xl mt-10 md:mt-0 md:text-8xl md:mb-6 text-white text-center md:text-left">
            Ready To Start
            <span className="text-cyan-400 block w-fit pb-3 text-center md:text-left">The Project?</span>
          </h1>
          <p className="md:text-2xl text-sm mb-6 text-white block font-extralight text-center md:text-left">
            Let's Collaborate to Craft Digital Experiences Tailored Just for You.
          </p>
          <div className="mt-4">
            <button
              onMouseEnter={handleHover}
              onMouseLeave={handleHoverLeave}
              onClick={handleNavigate}
              className="text-white py-3 px-5 rounded-full flex justify-center items-center mt-4 hover:border-black border-white border-2 hover:bg-white group transition-colors duration-300"
            >
              <div className="font-medium group-hover:text-black transition-colors duration-300">
                Start a Project
              </div>
              <div className="w-[20px] h-[20px] bg-white rounded-full ml-6 group-hover:bg-black transition-colors duration-300"></div>
            </button>
          </div>
        </div>
      </section>
        {/* Flex container for the content */}
        <h1 ref={h1Ref} className="text-white md:text-9xl text-7xl text-center font-thin my-16 relative z-50">Our Team.</h1>
        <div className="flex flex-row mb-16 text-white bg-white/10 rounded-3xl max-w-5xl md:mx-auto p-6 md:space-y-0 md:space-x-4 md:h-auto md:px-8 mx-3 h-[40vh] relative z-50">

          {/* Left side: Subheading and paragraph */}
          <div className="flex-1 p-0 md:p-4 flex flex-col">
            <img src="/images/nexuz-logo-1.png" className="mr-auto h-7 md:h-16"></img>
            <div className="flex justify-end flex-col flex-grow">
              <div>
                <h2 className="text-3xl md:text-8xl  md:mb-4  mt-auto ">{contentArray[currentState].firstName}</h2>
              </div>
              <div className="flex justify-start">
                <h2 className="text-2xl md:text-7xl  mb-1 text-cyan-500 ">{contentArray[currentState].lastName}</h2>
              </div>
              <div className="flex justify-start">
                <p className="text-xs md:text-2xl font-extralight mt-auto">{contentArray[currentState].email}</p>
              </div>
            </div>

            {/* Button for Next Member */}
            <div className="flex justify-start mt-4">
              <button
                className="bg-white text-white md:py-3 md:px-6 px-3 py-2  rounded-full hover:bg-cyan-400 flex items-center text-sm md:text-lg"
                onClick={handleNext}
                style={{
                  backgroundImage: "url('/images/teambutton.gif')",
                  backgroundSize: 'fit',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                {/* The button displays "Next" with the next member's name */}
                Next: {contentArray[(currentState + 1) % contentArray.length].firstName}
                <div className="w-[20px] h-[20px] bg-white rounded-full ml-4"></div>
              </button>
            </div>
          </div>

          {/* Right side: Images */}
          <div className="flex-shrink-0 md:w-[25vw] w-[35vw] flex flex-col justify-center items-center px-2 md:px-4">
            {/* Aspect Ratio Container */}
            <div className="relative w-full" style={{ paddingBottom: '125%' }}> {/* 16:9 Aspect Ratio */}
              <img
                src={contentArray[currentState].img}
                alt={`Image for state ${currentState + 1}`}
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
              />
            </div>
            <p style={{ whiteSpace: 'pre-line' }} className="text-xs md:text-2xl mt-2 text-center text-white/80 ">
              {contentArray[currentState].role}
            </p>
          </div>


          {/* Cofounder section */}

        </div>




      
    </div>
  );
};

export default TeamSection;
