import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {
  const containerRef = useRef(null);
  const containerRefAni = useRef(null);
  const h1Ref = useRef(null);


  const contentArray = [
    { subheading: 'Webapps.' },
    { subheading: 'UI/UX.' },
    { subheading: 'Branding.' },
    { subheading: 'Animations.' },
    { subheading: 'Packaging.' },
  ];

  // Animations
  useLayoutEffect(() => {
    if (!containerRefAni.current || !containerRef.current || !h1Ref.current) return;
    gsap.fromTo(
      h1Ref.current,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: containerRef.current,
          start: 'top 60%',
          end: 'top 30%',
          scrub: true,
        },
      }
    );

  const h2Elements = containerRefAni.current.querySelectorAll('h2');

  // Animate each <h2> element
  h2Elements.forEach((h2) => {
    gsap.fromTo(
      h2,
      { x: 200, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: h2,
          start: 'top 80%',
          end: 'top 0%',
          scrub: true,
        },
      }
    );

    gsap.to(h2, {
      x: -300,
      opacity: 0,
      scrollTrigger: {
        trigger: h2,
        start: 'bottom 0%',
        end: 'top 0%',
        scrub: true,
      },
    });
  });

  // Pinning the container and animating background
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: containerRef.current,
      start: 'top top',
      end: '+=1200',
      pin: true,
      scrub: true,
    },
  });

  tl.fromTo(
    containerRef.current,
    { backgroundPosition: '50% 50%' },
    {
      backgroundPosition: '100% 0%',
      ease: 'none',
    }
  );

  // Cleanup
  return () => {
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  };
}, []);


return (
  <div
    id="what-we-do"
    className="w-screen h-fit md:rounded-t-3xl border-t-2 z-20 border-t-cyan-400 mb-0  custom-shadow relative overflow-x-hidden"
  >
    {/* Header Section */}
    <div className="whitespace-nowrap">

    </div>
    <div
      ref={containerRef}
      className="flex flex-col justify-center pt-5 h-screen"
      style={{
        background: 'url(/images/webappsbg.jpg)',
        backgroundSize: '15%',
        backgroundPosition: '50% 50%',
        transition: 'background-image 0.8s ease-in-out',
      }}
    >
      <h1           ref={h1Ref}
className="text-white lg:text-9xl text-7xl text-center font-thin mb-16">Services.</h1>
      <div className="text-center text-white" ref={containerRefAni}>
        {contentArray.map((content, idx) => (
          <h2 key={idx} className="md:text-8xl text-5xl font-bold">
            {content.subheading}
          </h2>
        ))}
      </div>
    </div>
  </div>
);
};

export default AboutSection;
