import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import ProjectBG from './ProjectBG';

const Privacy = () => {
    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        // Prevent scrolling when the image is visible
        if (showImage) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Hide the image after 5 seconds
        const timer = setTimeout(() => setShowImage(false), 2800);

        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto'; // Reset overflow on cleanup
        };
    }, [showImage]);

    return (
        <>
            {showImage && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black"
                    style={{ overflow: 'hidden' }}
                >
                    <img
                        src="/images/privacylap.png" // Replace with your PNG image path
                        alt="Loading..."
                        className="max-w-full max-h-full"
                    />
                </div>
            )}
            {!showImage && (
                <div className="bg-black relative">
                    <ProjectBG />
                    <Navbar />
                    <div className="privacy-policy text-white sm:mt-16 mt-10 sm:ml-5 px-5 mb-5 md:p-12 relative">
                        <h1 className="text-6xl md:text-8xl mb-8 text-white">Privacy Policy.</h1>
                        <p className="text-lg mb-6 font-extralight">
                            This Privacy Policy outlines how we handle your personal information collected through form submissions for project inquiries, meeting scheduling, and other communication. We prioritize your privacy and ensure that your data is secure and handled responsibly.
                        </p>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">1. Information Collection</h2>
                            <p className="text-base font-extralight">
                                We collect personal information that you provide directly, such as your name, email, contact number, and any additional information relevant to setting up meetings and initiating projects. This information helps us reach out to you to discuss project requirements and potential collaborations.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">2. Use of Information</h2>
                            <p className="text-base font-extralight">We use your information for the following purposes:</p>
                            <ul className="list-disc list-inside text-base font-extralight ml-6">
                                <li>Contacting you about your project inquiries, setting up meetings, and discussing your goals.</li>
                                <li>Providing information on our services, if requested.</li>
                                <li>Complying with legal and regulatory obligations as required.</li>
                            </ul>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">3. Data Retention</h2>
                            <p className="text-base font-extralight">
                                We retain your personal information only as long as necessary to fulfill the purposes outlined in this policy or as required by law. When it is no longer needed, we securely delete or anonymize your information.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">4. Data Security</h2>
                            <p className="text-base font-extralight">
                                We employ industry-standard security measures to safeguard your personal information, including encryption, secure storage solutions, and routine security audits. However, please note that no data transmission over the internet is 100% secure.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">5. Sharing of Information</h2>
                            <p className="text-base font-extralight">
                                We do not sell or trade your personal information to third parties. We may share your data with trusted service providers solely for the purpose of supporting our services, under strict confidentiality agreements.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">6. Your Rights</h2>
                            <p className="text-base font-extralight">
                                Depending on your location, you may have the right to access, correct, delete, or restrict the processing of your personal information. To exercise these rights, please contact us through our website.
                            </p>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">7. Changes to This Policy</h2>
                            <p className="text-base font-extralight">
                                We may update this policy periodically. Your continued use of our site after any updates indicates your acceptance of the revised terms.
                            </p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-bold text-cyan-400 mb-2">8. Contact Us</h2>
                            <p className="text-base font-extralight">
                                If you have any questions regarding this policy, please contact us through the contact information provided on our website.
                            </p>
                        </section>
                    </div>
                    <Footer />
                </div>
            )}
        </>
    );
};

export default Privacy;
