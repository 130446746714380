import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/effect-cards';
import { EffectCards } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger);

const BentoBoxes = () => {
  const containerRef = useRef(null);

  const items = [
    { id: 1, type: "image", src: "/images/pindt1.png", alt: "Video 1", width: "300px", height: "300px", direction: "left" },
    { id: 2, type: "video", src: "/images/nexuzani.mp4", alt: "Video 1", width: "250px", height: "300px", direction: "left" },
    { id: 3, type: "video", src: "/images/bentovid2.mp4", alt: "Video 2", width: "450px", height: "300px", direction: "left" },
    { id: 4, type: "video", src: "/images/bentovid3.mp4", alt: "Video 3", width: "450px", height: "300px", direction: "left" },
    { id: 5, type: "image", src: "/images/bottlebento.jpg", alt: "Image 1", width: "200px", height: "300px", direction: "left" },
    { id: 6, type: "video", src: "/images/architsubento.mp4", alt: "Video 4", width: "500px", height: "300px", direction: "right" },
    { id: 7, type: "image", src: "/images/optibento.jpeg", alt: "Image 2", width: "300px", height: "300px", direction: "right" },
    { id: 8, type: "image", src: "/images/carbonforgebento.png", alt: "Image 3", width: "300px", height: "300px", direction: "right" },
    { id: 9, type: "image", src: "/images/carbonforgebento2.png", alt: "Image 4", width: "400px", height: "300px", direction: "right" },
    { id: 10, type: "video", src: "/images/bentovid1.mp4", alt: "Video 5", width: "200px", height: "300px", direction: "right" },
    { id: 11, type: "image", src: "/images/sensodyne.jpeg", alt: "Image 5", width: "200px", height: "350px", direction: "left" },
    { id: 12, type: "image", src: "/images/bru.jpeg", alt: "Image 5", width: "300px", height: "250px", direction: "left" },
    { id: 13, type: "image", src: "/images/movalbento.jpeg", alt: "Gallery Image 3", width: "500px", height: "300px", direction: "left" },
    { id: 14, type: "image", src: "/images/moodboardbento.png", alt: "Gallery Image 2", width: "450px", height: "300px", direction: "left" },
    { id: 15, type: "image", src: "/images/pindm3.jpeg", alt: "Gallery Image 2", width: "200px", height: "200px", direction: "left" },

  ];

  const images = items.filter(item => item.type === "image");
  const videos = items.filter(item => item.type === "video");


  useEffect(() => {
    const rows = [...containerRef.current.children]; // Get all rows

    rows.forEach((row) => {
      const rowItems = [...row.children]; // Get all items in the row

      rowItems.forEach((item, index) => {
        const direction = item.dataset.direction === "right" ? "200px" : "-200px";

        gsap.fromTo(
          item,
          { opacity: 0, x: direction },
          {
            opacity: 1,
            x: 0,
            delay: index * 5, // Add subtle lag between items
            scrollTrigger: {
              trigger: row,
              start: "top 70%",
              end: "top 20%",
              scrub: 1,
            },
          }
        );

      });
    });
  }, []);

  return (
    <div>
      <div ref={containerRef} className="h-auto p-4 my-10 hidden md:block">
        <div className="flex flex-wrap gap-4 relative z-50 justify-center">
          {items.map((item, index) => (
            <div
              key={`${item.id}-${index}`}
              className="bento-box bg-white/10 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 flex-shrink-0"
              style={{
                width: item.width,
                height: item.height,
              }}
              data-direction={item.direction}
            >
              {item.type === "image" ? (
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-full h-full object-cover"
                />
              ) : (
                <video
                  muted
                  loop
                  playsInline
                  webkit-playsInline
                  controls
                  className="w-full h-full object-cover"
                  src={item.src}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="block md:hidden mt-10 p-4">
        {/* Image Swiper */}
        <Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className="mySwiper"  cardsEffect={{
        slideShadows: true, // Add shadows for depth
        perspective: 800,   // Adjust perspective (lower = flatter)
        rotate: true,       // Enable rotation
        stretch: 5,        // Control spacing between cards
      }}>
          {images.map((item, index) => (
            <SwiperSlide key={index} className="w-auto">
              <div
                className="bento-box bg-white/100 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 flex-shrink-0"
                style={{
                  width: parseFloat(item.width) / 1.5 + "px",
                  height: parseFloat(item.height) / 1.5 + "px",
                }}
                              >
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-full h-full object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Video Swiper */}
        <Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className="mySwiper"  cardsEffect={{
        slideShadows: true, // Add shadows for depth
        perspective: 800,   // Adjust perspective (lower = flatter)
        rotate: true,       // Enable rotation
        stretch: 10,        // Control spacing between cards
      }}>          {videos.map((item, index) => (
            <SwiperSlide key={index} style={{ width: "80%" }}>
              <div
                className="bento-box  rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 flex-shrink-0"
                style={{
                  width: parseFloat(item.width) / 1.5 + "px",
                  height: parseFloat(item.height) / 1.5 + "px",
                }}              >
                <video
                  autoPlay
                  muted
                  loop
                  controls
                  className="w-full h-full object-cover"
                  src={item.src}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>


    </div>

  );
};

export default BentoBoxes;
