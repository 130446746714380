import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Navbar from './Navbar';
import AnimatedBackground from './AnimatedBackground';
import { useNavigate } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {
  const [isTypingDone, setTypingDone] = useState(false);
  const navigate = useNavigate();
  const sectionRef = useRef(null);
  const backLayerRef = useRef(null);

  const handleNavigate = () => {
    navigate('/projects');
  };

  // Typing effect timer
  useEffect(() => {
    const typingDuration = 3000;
    const timer = setTimeout(() => {
      setTypingDone(true);
    }, typingDuration);

    return () => clearTimeout(timer);
  }, []);

  // GSAP ScrollTrigger Animation
  useEffect(() => {
    if (!sectionRef.current) return;

    const section = sectionRef.current;

    // GSAP ScrollTrigger Animation
    const animation = gsap.fromTo(
      section,
      { scale: 1, opacity: 1 },
      {
        scale: 1.2,
        opacity: 0,
        ease: 'power1.out',
        scrollTrigger: {
          id: 'heroSectionScroll', // Unique ID for debugging and cleanup
          trigger: section,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      }
    );

    return () => {
      // Cleanup specific trigger by ID
      const trigger = ScrollTrigger.getById('heroSectionScroll');
      if (trigger) trigger.kill();

      // Kill associated GSAP animation
      animation.kill();
    };
  }, []);

  // Mouse movement effect
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!backLayerRef.current) return;

      const { clientX, clientY } = e;
      const { innerWidth, innerHeight } = window;

      // Calculate movement offsets
      const offsetX = ((clientX - innerWidth / 2) / innerWidth) * 50;
      const offsetY = ((clientY - innerHeight / 2) / innerHeight) * 50;

      gsap.to(backLayerRef.current, {
        x: offsetX,
        y: offsetY,
        duration: 0.2,
        overwrite: 'auto', // Prevent conflicts
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="relative min-h-screen max-w-screen z-20 overflow-hidden">
      <AnimatedBackground reverse={false} />
      <Navbar />
      <section
        className="relative z-10 flex justify-center items-center w-screen min-h-screen bg-cover bg-center bg-no-repeat overflow-hidden"
        ref={sectionRef}
      >
 

        {/* Content */}
        <div className="flex flex-col justify-between items-center text-center max-w-4xl w-full px-4">
          <h1 className="text-7xl  lg:text-[180px] text-white mb-6">
            We create
            <span
              className={`text-cyan-400 animate-typing overflow-hidden whitespace-nowrap border-r-4 ${
                isTypingDone ? 'border-transparent' : 'border-white'
              } block w-full md:w-auto pb-3 lg:text-9xl  text-5xl md:text-7xl`}
            >
              Digital Products.
            </span>
          </h1>
          <p className="md:text-2xl text-lg text-white font-extralight mb-6">
            Crafting immersive experiences that captivate and connect.
          </p>
          <button
            onClick={handleNavigate}
            className="text-white py-3 px-5 rounded-full flex justify-center items-center mt-4 hover:border-black border-white border-2 hover:bg-white group transition-colors duration-300"
          >
            <div className="font-medium group-hover:text-black transition-colors duration-300">
              Start a Project
            </div>
            <div className="w-[20px] h-[20px] bg-white rounded-full ml-6 group-hover:bg-black transition-colors duration-300"></div>
          </button>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
