import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FiX, FiMinus } from 'react-icons/fi';
import ProjectBG from './ProjectBG';
import BoxDisplay from './BoxDisplay'; // Assuming BoxDisplay is defined in a separate file
import BentoBoxes from './BentoBoxes';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

gsap.registerPlugin(ScrollTrigger);

const ProjectsSection = () => {
  const [expandedCard, setExpandedCard] = useState(null);
  const h1Ref = useRef(null);
  // Animations

  // Animations
  useEffect(() => {
    if (!h1Ref.current) return;

    gsap.fromTo(
      h1Ref.current,
      { opacity: 0 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: h1Ref.current,
          start: 'top 70%',
          end: 'top 30%',
          scrub: true,
        },
      }
    );

    // Cleanup function to remove ScrollTriggers
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);




  const projects = [
    {
      title: "Optifinish",
      tags: "Packaging",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/optifinishbg.gif?alt=media&token=cb51f0ba-1fa0-4bc2-b5ce-5a90c9cf6fa1",
      logo: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/optifinish.png?alt=media&token=1c6a9d59-e7b2-4513-ad02-cd5226ceb808",
      description: "Industrial Packaging Design.",
      details: "This new packaging design led to a sale of over 2000 boxes of this product in the last quarter.",
      show3DModel: true,
      showVid: false,
      images: ["https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/optipro.png?alt=media&token=12372475-52c8-4cd5-b257-a7f897cedcca", "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/optipro2.png?alt=media&token=1624c8b9-10e7-4954-84bf-e7c50180350f"],
    },
    {
      title: "Architsu",
      tags: "Branding, Animation",
      logo: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/Architsu.png?alt=media&token=da4b2a58-8cb3-4493-90fc-edbbf6a93452",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/architsubg.gif?alt=media&token=c909fd44-40ab-49c9-9c06-65d3cf2da65d",
      description: "Strategic Branding and Animation.",
      details: "Our designers handcrafted the logo and the company's animation to depict Architsu's true value to their customers.",
      show3DModel: false,
      showVid: true,
      videoSrc: "/images/architsufinal.MP4",
      images: ["https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/archetsum1.png?alt=media&token=ad4bfe7c-880e-47a9-98a6-11ed4ce328ef", "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/archetsum2.png?alt=media&token=34d9c923-bf3e-49b6-9567-7f924d9c58bf"],
    },
    {
      title: "Pind",
      tags: "Branding",
      logo: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/pindnb.png?alt=media&token=16fc7500-7a2b-4e6e-8551-5e1ac2513bf1",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/pindbg.gif?alt=media&token=31b0b20a-1ea0-4771-afdb-c885e6d5b0ca",
      description: "Luxury Branding and Packaging.",
      details: "Our team curated the brand identity, webapp and packaging for Pind. We also designed their jerseys.",
      show3DModel: false,
      showVid: false,
      images: ["https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/pindm1.png?alt=media&token=2ddd645f-11a0-4664-93b0-80f15176cc00", "/images/pindm2.jpeg", "/https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/pindt2.png?alt=media&token=531f710e-cc8b-4521-aa51-95003e1c40e6"],
    },
    {
      title: "Food 4 Fortuneless",
      tags: "Branding",
      logo: "/images/f4flogo.png",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/f4ffinalbg.gif?alt=media&token=699a7808-7bc4-4d1b-b0b3-044ecc09a9f0",
      description: "Non-Profit Brand Identity.",
      details: "Crafted a distinctive branding and logo.",
      show3DModel: false,
      showVid: false,
      images: ["/images/f4fm1.png", "/images/f4fm2.png"],
    },
    {
      title: "Audacity",
      tags: "Branding, Webapp",
      logo: "/images/audacitynobg.png",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/audbg.gif?alt=media&token=ee661fc7-42dc-4272-8a85-74094583af64",
      description: "Cultural Learning Platform Brand Design.",
      details: "Designed a cohesive brand identity for Audacity and helped create UI/UX for their education platform",
      show3DModel: false,
      showVid: false,
      images: ["/images/audacitym1.png", "/images/audacitym2.png"],
    },
    {
      title: "Apex Learning",
      tags: "Branding, Webapp",
      logo: "/images/apexnobg.png",
      bg: "https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/apexbg2.GIF?alt=media&token=9a4e0ce2-2b84-4688-9619-fe6bfa789489",
      description: "Comprehensive EdTech Brand Design Suite.",
      details: "Crafted a full branding package and an edtech webapp for Apex Learning.",
      show3DModel: false,
      whowVid: false,
      images: ["/images/apexm1.png", "/images/apexm2.jpeg"],
    },
  ];

  return (
    <div id="projects" className="w-screen h-auto relative overflow-x-hidden mb-1">
      <ProjectBG />
      {/* Projects Grid */}
      <h1 ref={h1Ref} className="text-white md:text-9xl text-5xl text-center font-thin md:my-16 relative z-50 my-10">Featured Projects.</h1>
      <div className=" md:grid-cols-3 gap-8 px-16 relative z-50 hidden md:block md:grid">
        {projects.map((project, index) => (
          <div
            key={index}
            className="project-box relative flex flex-col items-center justify-center p-6 rounded-lg shadow-lg border-[10px] border-white/10 h-[250px] md:h-[300px]"
            style={{
              backgroundImage: `url('${project.bg}')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            onClick={() => setExpandedCard(project)}
          >
            {/* Project Logo */}
            <img
              src={project.logo}
              alt={`${project.title} logo`}
              className="md:h-20 h-14 w-auto object-contain mb-4"
            />
            {/* Project Info */}
            <h2 className="md:text-6xl text-5xl text-white text-center font-extralight">{project.title}</h2>
            <span className="mt-2 md:text-4xl text-xl font-thin text-gray-200">{project.tags}</span>
          </div>
        ))}
      </div>

      {/* Swiper Carousel for Small Screens */}
      <Swiper
        spaceBetween={20} // Controls spacing between cards
        slidesPerView={'auto'} // Makes slides width adaptive
        centeredSlides={true} // Centers the active slide
        loop={true} // Enables infinite scrolling
        className="mySwiper md:hidden"
      >
        {projects.map((project, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: '80%', // Set slide width
              cursor: 'pointer',
            }}
            onClick={() => setExpandedCard(project)}
          >
            <div
              className="project-box md:hidden relative flex flex-col items-center justify-center p-6 rounded-lg shadow-lg border-[10px] border-white/10 h-[300px]"
              style={{
                backgroundImage: `url('${project.bg}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {/* Project Logo */}
              <img
                src={project.logo}
                alt={`${project.title} logo`}
                className="h-14 w-auto object-contain mb-4"
              />
              {/* Project Info */}
              <h2 className="text-5xl text-white text-center font-extralight">{project.title}</h2>
              <span className="mt-2 text-xl font-thin text-gray-200">{project.tags}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Expanded Project Modal */}
      {expandedCard && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-[100] backdrop-blur-lg">
          <div className="bg-[#2d2d2d]/60 rounded-3xl p-8 md:w-4/5 w-[90vw] max-h-[95vh] overflow-y-auto relative">
            <button
              onClick={() => setExpandedCard(null)}
              className="absolute top-4 right-4 text-cyan-500 hover:text-white"
            >
              <FiMinus className="h-8 w-8" />
            </button>
            <h3 className="md:text-7xl text-5xl font-thin text-white mb-4">
              {expandedCard.title}
            </h3>
            <p className="text-cyan-500 mb-4 font-extralight md:text-3xl">
              {expandedCard.description}
            </p>
            <p className="text-gray-100 mb-4 md:text-xl">{expandedCard.details}</p>
            {expandedCard.images.length > 0 && (
              <div
                className={`grid gap-4 mt-4 ${expandedCard.images.length +
                  (expandedCard.show3DModel ? 1 : 0) +
                  (expandedCard.showVid ? 1 : 0) === 1
                  ? "md:grid-cols-1"
                  : expandedCard.images.length +
                    (expandedCard.show3DModel ? 1 : 0) +
                    (expandedCard.showVid ? 1 : 0) === 2
                    ? "md:grid-cols-2"
                    : "md:grid-cols-3"
                  }`}
              >
                {expandedCard.images.map((image, index) => (
                  <div key={index} className="relative w-full h-full overflow-hidden rounded-3xl">
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}

                {/* Ensure 3D Model is displayed in the same row */}
                {expandedCard.show3DModel && (
                  <div className="relative w-full h-full overflow-hidden rounded-3xl flex items-center justify-center">
                    <BoxDisplay />
                  </div>
                )}

                {/* Ensure Video is displayed in the same row */}
                {expandedCard.showVid && (
                  <div className="relative w-full h-full overflow-hidden rounded-3xl">
                    <video
                      src={expandedCard.videoSrc} // Assuming `videoSrc` contains the video URL
                      controls
                      autoPlay
                      loop
                      muted
                      className="w-full h-full object-cover"
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}
              </div>

            )}
          </div>

        </div>
      )}
      <BentoBoxes />
    </div>
  );
};

export default ProjectsSection;
