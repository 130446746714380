import React, { useState, useEffect, useRef } from 'react';
import { HiMenu } from 'react-icons/hi'; // For the hamburger menu icon
import { IoMdClose } from 'react-icons/io'; // For the close icon
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu state
  const navigate = useNavigate();
  const menuRef = useRef(null);
  let startX = 0;

  // Function to handle navigation to LandingPage and scroll to a specific section
  const handleNavigate = (sectionId) => {
    navigate('/', { state: { scrollTo: sectionId } }); // Pass the section ID in the state
    setIsMenuOpen(false); // Close the menu after navigation
  };

  // Prevent scrolling while menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  // Close mobile menu when window is resized to larger width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Close menu when clicking outside of it
  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  // Handle touch swipe to close the menu
  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    // Close if swipe is to the left
    if (deltaX > 50) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isMenuOpen]);

  return (
    <nav className="z-50 backdrop-blur-3xl md:translate-y-10 md:bg-white/10 md:rounded-3xl md:mx-10 shadow-2xl w-full md:w-auto mx-0 relative">
      <div className="mx-auto px-2 sm:px-2 lg:px-4">
        <div className="flex justify-between h-12 items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <picture>
              <source media="(max-width: 768px)" srcSet="https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/nexuzwhite.png?alt=media&token=75799e7e-0146-4a6f-9797-1cb6d76aed13" />
              <img src="https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/nexuz-logo-1.png?alt=media&token=51d3fb23-481a-4ffd-8130-13c3f2b75be9" alt="Nexuz Logo" className="h-12 md:h-10" />
            </picture>
          </div>
          {/* Hamburger/Close Menu Icon */}
          <div className="flex md:hidden items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="z-[9999]"
              style={{
                pointerEvents: "auto",
                backgroundImage: isMenuOpen
                  ? "url('https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/mobmenuclose2.png?alt=media&token=e7555e70-033e-475a-93c4-fa1771f59944')"
                  : "url('https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/mobmenuopen.png?alt=media&token=8ac374df-0055-487d-9f6e-a9be4f7f6682')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                transition: "background-image 0.5s ease-in-out",
                width: "40px", // Set width for the button
                height: "40px", // Set height for the button
                border: "none", // Remove default button border
                outline: "none", // Remove focus outline
                cursor: "pointer", // Ensure pointer cursor
              }}
            >
              {/* Empty button, image will be shown as background */}
            </button>
          </div>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-8 flex-grow justify-end  text-base">
            <button onClick={() => handleNavigate('hero')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Home
            </button>
            <button onClick={() => handleNavigate('what-we-do')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              What We Do?
            </button>
            <button onClick={() => handleNavigate('team')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Our Team
            </button>
            <button onClick={() => handleNavigate('projects')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Featured Projects
            </button>
            <button onClick={() => handleNavigate('reviews')} className="text-white hover:text-cyan-300 inline-block px-4 py-2 my-auto">
              Client Reviews
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="fixed top-0 right-0 h-screen w-screen z-40 p-6 flex flex-col justify-between "
          style={{
            backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/button.gif?alt=media&token=8c528807-642d-4b3d-bf28-c365bdbbcfac'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Add a gradient directly
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundBlendMode: "multiply", // Blend the gradient with the image
          }}
        >
          
          <div className="flex flex-col space-y-6 mt-16">
            {/* Navigation Links */}
            <button onClick={() => handleNavigate('what-we-do')} className="text-white hover:text-cyan-400 block text-4xl text-right font-thin">
              What We Do?
            </button>
            <button onClick={() => handleNavigate('team')} className="text-white hover:text-cyan-400 block text-4xl text-right font-thin">
              Our Team
            </button>
            <button onClick={() => handleNavigate('projects')} className="text-white hover:text-cyan-400 block text-4xl text-right font-thin">
              Featured Projects
            </button>
            <button onClick={() => handleNavigate('reviews')} className="text-white hover:text-cyan-400 block text-4xl text-right font-thin">
              Client Reviews
            </button>
          </div>
          <div
          className="-z-10"
          style={{
            WebkitMaskImage: "url('/images/nexuzwhite.png')",
            maskImage: "url('/images/nexuzwhite.png')",
            WebkitMaskSize: "contain",
            maskSize: "contain",
            WebkitMaskRepeat: "no-repeat",
            maskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            maskPosition: "center",
            backgroundImage: "url('/images/buttonreverse.gif'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "multiply", // Blend the gradient with the image
            width: "100%",
            height: "100%"

          }}
        ></div>

          {/* Footer */}
          <div className="text-white text-center text-lg mt-6">
            <p>© 2025 Nexuzforge Private Limited.</p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
