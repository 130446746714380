import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './/components/LandingPage';  // Your default landing page
import StartProject from './/components/StartProject';  // The project section
import Privacy from './/components/Privacy';
import { useEffect, useState, useRef } from 'react';
import './App.css';
import html2canvas from 'html2canvas';




function App() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      {/* Custom Cursor */}
      <div
        className="custom-cursor"
        style={{
          top: `${cursorPosition.y}px`,
          left: `${cursorPosition.x}px`,
        }}
      ></div>
      <Router>
        <Routes>
          {/* Route for the landing page */}
          <Route path="/" element={<LandingPage />} />
          {/* Route for the ProjectSection page */}
          <Route path="/projects" element={<StartProject />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
