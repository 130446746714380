import React, { useEffect, useState } from 'react';

const LandingAnimation = ({ onAnimationComplete }) => {
  const [animationImage, setAnimationImage] = useState('https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/landinglap.png?alt=media&token=f14da986-b588-4b0f-a4de-9391cd5d80e8');

  useEffect(() => {
    // Detect screen width and update the animation image
    const updateAnimationImage = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;
      setAnimationImage(isMobile ? 'https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/landingmob.png?alt=media&token=bf32f3df-0da6-4e77-97c1-e028bc632003' : 'https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/landinglap.png?alt=media&token=f14da986-b588-4b0f-a4de-9391cd5d80e8');
    };

    updateAnimationImage(); // Initial check
    window.addEventListener('resize', updateAnimationImage); // Update on resize

    return () => {
      window.removeEventListener('resize', updateAnimationImage); // Cleanup listener
    };
  }, []);


  return (
    <div
      className="w-full h-[90vh] bg-black flex items-center justify-center z-50"
    >
      <img
        src={animationImage}
        className="w-full h-[90vh] object-contain"
        alt="Landing Animation"
      />
    </div>
  );
};

export default LandingAnimation;
