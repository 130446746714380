import React from 'react';

const AnimatedBackground = ({ reverse = false }) => {
  // Inline styles for background animation and overlay
  const backgroundStyles = {
    position: 'absolute',
    inset: 0,
    zIndex: 0,
    background: 'linear-gradient(to bottom right, #0d9488, #1e3a8a, #4338ca, #9333ea, #0d9488)', // Initial gradient colors
    backgroundSize: '300% 300%', // Make it larger to enable smooth movement
    animation: `gradientMove 8s ease infinite ${reverse ? 'reverse' : 'normal'}`, // Reverse or normal direction
  };

  const overlayStyles = {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'black',
    opacity: 0.5,
    mixBlendMode: 'multiply',
    pointerEvents: 'none', // Allow clicks to pass through
  };

  return (
    <>
      <div style={backgroundStyles} className="w-[100vw]"></div>
      <div style={overlayStyles} className="w-[100vw]"></div>
    </>
  );
};

// Define the keyframes using JavaScript (for React)
const styles = `
  @keyframes gradientMove {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 75% 50%;
    }
    50% {
      background-position: 100% 100%;
    }
    75% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;

// Inject the keyframes into the document (React-specific)
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default AnimatedBackground;
