import React, { useEffect, useState } from 'react';

const LandingAnimation = ({ onAnimationComplete }) => {
  const [animationImage, setAnimationImage] = useState('/images/landinglap.png');

  useEffect(() => {
    // Detect screen width and update the animation image
    const updateAnimationImage = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;
      setAnimationImage(isMobile ? '/images/landingmob.png' : '/images/landinglap.png');
    };

    updateAnimationImage(); // Initial check
    window.addEventListener('resize', updateAnimationImage); // Update on resize

    return () => {
      window.removeEventListener('resize', updateAnimationImage); // Cleanup listener
    };
  }, []);


  return (
    <div
      className="w-full h-[90vh] bg-black flex items-center justify-center z-50"
    >
      <img
        src={animationImage}
        className="w-full h-[90vh] object-contain"
        alt="Landing Animation"
      />
    </div>
  );
};

export default LandingAnimation;
