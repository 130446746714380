import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu state
  const navigate = useNavigate();
  let startX = 0;

  // Navigate + scroll, then close menu
  const handleNavigate = (sectionId) => {
    navigate('/', { state: { scrollTo: sectionId } });
    setIsMenuOpen(false);
  };

  // Prevent scrolling while menu is open
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  // Auto-close menu when resizing above 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Swipe to close on mobile
  const handleTouchStart = (e) => {
    startX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - startX;
    // Close if swipe right (deltaX > 50)
    if (deltaX > 50) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="z-50 backdrop-blur-3xl md:translate-y-10 md:bg-white/10 md:rounded-3xl md:mx-10 shadow-2xl w-full md:w-auto mx-0 relative">
      <div className="mx-auto px-2 sm:px-2 lg:px-4">
        <div className="flex justify-between h-12 items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <picture>
              <source media="(max-width: 768px)" srcSet="/images/nexuzwhite.png" />
              <img src="/images/nexuz-logo-1.png" alt="Nexuz Logo" className="h-12 md:h-10" />
            </picture>
          </div>

          {/* Hamburger/Close Toggle Button */}
          <div className="flex md:hidden items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="z-[9999]"
              style={{
                pointerEvents: 'auto',
                backgroundImage: isMenuOpen
                  ? "url('/images/mobmenuclose2.png')"  // "X" image
                  : "url('/images/mobmenuopen.png')",   // Hamburger image
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transition: 'background-image 0.5s ease-in-out',
                width: '40px',
                height: '40px',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              }}
            >
              {/* Empty button – background image is the icon */}
            </button>
          </div>

          {/* Desktop Nav Links */}
          <div className="hidden md:flex space-x-8 flex-grow justify-end text-base">
            <button onClick={() => handleNavigate('hero')} className="text-white hover:text-cyan-300 px-4 py-2">
              Home
            </button>
            <button onClick={() => handleNavigate('what-we-do')} className="text-white hover:text-cyan-300 px-4 py-2">
              What We Do?
            </button>
            <button onClick={() => handleNavigate('team')} className="text-white hover:text-cyan-300 px-4 py-2">
              Our Team
            </button>
            <button onClick={() => handleNavigate('projects')} className="text-white hover:text-cyan-300 px-4 py-2">
              Featured Projects
            </button>
            <button onClick={() => handleNavigate('reviews')} className="text-white hover:text-cyan-300 px-4 py-2">
              Client Reviews
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div
          className="fixed top-0 right-0 h-screen w-screen z-40 p-6 flex flex-col justify-between"
          style={{
            backgroundImage:
              "url('/images/button.gif'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          {/* Navigation Links */}
          <div className="flex flex-col space-y-6 mt-16">
            <button
              onClick={() => handleNavigate('what-we-do')}
              className="text-white hover:text-cyan-400 block text-4xl text-right font-thin"
            >
              What We Do?
            </button>
            <button
              onClick={() => handleNavigate('team')}
              className="text-white hover:text-cyan-400 block text-4xl text-right font-thin"
            >
              Our Team
            </button>
            <button
              onClick={() => handleNavigate('projects')}
              className="text-white hover:text-cyan-400 block text-4xl text-right font-thin"
            >
              Featured Projects
            </button>
            <button
              onClick={() => handleNavigate('reviews')}
              className="text-white hover:text-cyan-400 block text-4xl text-right font-thin"
            >
              Client Reviews
            </button>
          </div>

          {/* Optional "masked" background element */}
          <div
            className="-z-10"
            style={{
              WebkitMaskImage: "url('/images/nexuzwhite.png')",
              maskImage: "url('/images/nexuzwhite.png')",
              WebkitMaskSize: 'contain',
              maskSize: 'contain',
              WebkitMaskRepeat: 'no-repeat',
              maskRepeat: 'no-repeat',
              WebkitMaskPosition: 'center',
              maskPosition: 'center',
              backgroundImage:
                "url('/images/buttonreverse.gif'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundBlendMode: 'multiply',
              width: '100%',
              height: '100%',
            }}
          ></div>

          {/* Footer */}
          <div className="text-white text-center text-lg mt-6">
            <p>© 2025 Nexuzforge Private Limited.</p>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
