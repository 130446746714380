import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // LinkedIn icon
import { MdEmail } from 'react-icons/md'; // Email icon

const Footer = () => {
  return (
    <div className="overflow-hidden relative z-50 ">
      <footer
        className="text-white py-5 relative"
          style={{
            backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/nexuzforge-website.firebasestorage.app/o/button.gif?alt=media&token=8c528807-642d-4b3d-bf28-c365bdbbcfac'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", // Add a gradient directly
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundBlendMode: "multiply", // Blend the gradient with the image
            width: "100%",
            height: "100%", // Adjust to your requirements
          }}
      >
        {/* Mask for Background */}
        <div
          className="absolute inset-0 z-0"
          style={{
            WebkitMaskImage: "url('/images/nexuzwhite.png')",
            maskImage: "url('/images/nexuzwhite.png')",
            WebkitMaskSize: "contain",
            maskSize: "contain",
            WebkitMaskRepeat: "no-repeat",
            maskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            maskPosition: "center",
            backgroundImage: "url('/images/buttonreverse.gif'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "multiply", // Blend the gradient with the image
            width: "100%",
            height: "100%"

          }}
        ></div>

        {/* Nexuz Logo */}


        {/* Centralized Content */}
        <div className="flex flex-wrap justify-center items-start md:flex-row flex-col lg:gap-24 gap-4 mx-auto p-4">
          {/* About Us Section */}
          <div className="max-w-xs text-center md:text-left mx-auto">
            <h4 className="text-lg md:text-4xl font-bold">About us</h4>
            <p className="font-extralight">
              We craft engaging visuals and seamless user experiences to{' '}
              <span>drive growth and success.</span>
            </p>
          </div>

          {/* Contact Us Section */}
          <div className="max-w-xs text-center md:text-left mx-auto">
            <h4 className="font-bold text-lg md:text-4xl">Contact Us</h4>
            <p className="font-extralight">IND. +91 96434-03374</p>
            <p className="font-extralight">US. +1 (862) 357-5368</p>
            <p className="font-extralight">connectwithnexuz@gmail.com</p>
          </div>

          {/* Social Media Section */}
          <div className="max-w-xs text-center md:text-left mx-auto z-10">
            <h4 className="font-bold text-lg md:text-4xl">Social Media</h4>
            <p className="flex items-center justify-center md:justify-start">
              <a
                href="https://in.linkedin.com/company/nexuzdev"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <FaLinkedin className="mr-2 font-extralight" /> LinkedIn
              </a>
            </p>
          </div>
          <div className="max-w-xs text-center md:text-left mx-auto z-10">
            <h4 className="font-bold text-lg md:text-4xl">Quick Links</h4>
            <p className="flex items-center justify-center md:justify-start">
              <a href="/" className="font-extralight hover:underline">Home</a>
            </p>
            <p className="flex items-center justify-center md:justify-start">
              <a href="/privacy" className="font-extralight hover:underline">Privacy Policy</a>
            </p>
            <p className="flex items-center justify-center md:justify-start">
              <a href="/projects" className="font-extralight hover:underline">Start a Project</a>
            </p>
          </div>
        </div>

        {/* Footer Bottom Text */}
        <div className="text-center text-sm md:text-lg mt-6 font-thin">
          © 2025 Nexuzforge Private Limited, India. 
        </div>
      </footer>

      <style jsx global>{`
        :root {
          --neonColor: #FFFFFF; /* Cyan neon color */
        }

        .shadow-neon {
          box-shadow: 0px 50px 10px 10px var(--neonColor),
            0px 0px 20px 0px var(--neonColor); /* Neon glow effect */
        }
      `}</style>
    </div>
  );
};

export default Footer;
