// ThankYouPopup.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Ensure react-router-dom is installed
import { Link } from 'react-router-dom';


const ThankYouPopup = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to home page after 5 seconds
      navigate('/');
      // Alternatively, if not using react-router-dom, use:
      // window.location.href = '/';
    }, 7000);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] rounded-xl w-screen h-auto">
      <Link to="/">
    </Link>      
    <img className="mx-auto"src="/images/formsub.png"></img>
      </div>
    </div>
  );
};

export default ThankYouPopup;
